<template>
  <div class="ValetMartyrs">
    <div class="banner">
      <img
        src="@/assets/images/parkBanner.png"
        alt=""
      >
    </div>
    <div class="container">
      <Form
        :model="formItem"
        :label-width="100"
      >
        <FormItem label="亲属姓名：">
          <Input
            v-model="formItem.input"
            placeholder="请输入预约人姓名"
          ></Input>
        </FormItem>
        <FormItem label="联系方式：">
          <Input
            v-model="formItem.input"
            placeholder="请输入预约人联系方式"
          ></Input>
        </FormItem>
        <FormItem label="预约时间：">
          <!-- <Input v-model="formItem.input" placeholder="请选择预约时间"></Input> -->
          <DatePicker
            type="date"
            placeholder="请选择预约时间"
          ></DatePicker>
        </FormItem>
        <FormItem label="预约项目：">
          <!-- <Input v-model="formItem.input" placeholder="请输入预约人姓名"></Input> -->
          <ul class="projectList">
            <li
              v-for="(item,idx) in 5"
              :key="idx"
            >
              <img
                class="projectImg"
                src=""
              >
              <div class="projectName">388元鲜花套餐</div>
              <div class="projectPrice">
                ¥388
                <Tooltip
                  placement="top"
                  max-width="200"
                  theme="light"
                  content="388元鲜花套餐内容：1个花篮，1盒水果，4盘点心，祈福卡片，擦碑服务，拍摄照片及视频发送至客户微信。"
                >
                  <span>详情</span>
                  <!-- <div slot="content">
                    <p>388元鲜花套餐内容：1个花篮，1盒水果，4盘点心，祈福卡片，擦碑服务，拍摄照片及视频发送至客户微信。</p>
                  </div> -->
                </Tooltip>
                <s </div>
            </li>
          </ul>
        </FormItem>
        <FormItem label="逝者姓名：">
          <Input
            v-model="formItem.input"
            placeholder="请输入逝者姓名"
          ></Input>
        </FormItem>
        <FormItem label="墓穴位置：">
          <Input
            v-model="formItem.input"
            placeholder="请输入墓穴位置"
          ></Input>
        </FormItem>
        <FormItem label="">
          <div class="submitBtn">提交</div>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      obj:JSON.parse(this.$route.query.obj) || {},
      formItem: {},
    }
  },
  mounted(){
    window.location.href = this.titleUrl
  }
}
</script>

<style lang="less" scoped>
.ValetMartyrs {
  .banner {
    height: 220px;
  }
  .container {
    height: 660px;
    background: #ffffff;
    border-radius: 2px;
    margin: 24px auto 40px;
    padding: 24px;
    /deep/.ivu-input,
    .ivu-date-picker {
      width: 284px;
    }
    .projectList {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 161px;
        height: 222px;
        border: 1px solid #979797;
        margin-right: 21px;
        .projectImg {
          width: 160px;
          height: 160px;
        }
        .projectName {
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4d4d4d;
          line-height: 25px;
          margin: 4px 8px 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .projectPrice {
          font-size: 24px;
          font-weight: 400;
          color: #e64747;
          line-height: 33px;
          letter-spacing: 1px;
          margin: 0 8px 0;
          display: flex;
          align-items: center;
          /deep/.ivu-tooltip {
            margin-left: auto;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            color: #6085bb;
            line-height: 20px;
            cursor: pointer;
          }
        }
      }
    }
    .submitBtn {
      width: 116px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #299654;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      letter-spacing: 2px;
      cursor: pointer;
      &:active {
        opacity: 0.8;
      }
    }
  }
}
</style>